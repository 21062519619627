import React, {useEffect, useState} from "react";
import SocialsList from "../shared/components/SocialsList/SocialsList";
import Label from "../shared/components/Label/Label";
import ButtonPrimary from "../shared/components/Button/ButtonPrimary";
import SectionHero from "../shared/esim/SectionHero";
import contactUsImg from "../shared/img/contact-us-hero.png";
import {Transition} from "../headlessui";
import toast, {Toast} from "react-hot-toast";
import {useAppState} from "./config/AppStateContext";
import BgGlassmorphism from "../shared/esim/BgGlassmorphism";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import {translate} from "react-jhipster";

const PageContact = ({}) => {
    let tenant = {};
    const [isFormDisabled, setIsFormDisabled] = useState(false);

    const {state} = useAppState();
    const validationSchema = Yup.object().shape({
        fullName: Yup.string().required(translate("contact.full-name-required")),
        email: Yup.string().email(translate("contact.email-incorrect")).required(translate("contact.email-required")),
        message: Yup.string().required(translate("contact.message-required")),
    });
    useEffect(() => {
        tenant = state.tenant;
    }, [state.tenant]);

    const liveChatEnabled = state.tenant && state.tenant.liveChatEnabled !== undefined ? state.tenant.liveChatEnabled : true;
    const address = [
        state.tenant && state.tenant.address && state.tenant.address.street ? state.tenant.address.street : '',
        state.tenant && state.tenant.address && state.tenant.address.city ? state.tenant.address.city : '',
        state.tenant && state.tenant.address && state.tenant.address.state ? state.tenant.address.state : '',
        state.tenant && state.tenant.address && state.tenant.address.postalCode ? state.tenant.address.postalCode : '',
        state.tenant && state.tenant.address && state.tenant.address.country ? translate(`global.countries.${state.tenant.address.country.countryIso2}`) : '',
    ].filter(Boolean).join(', ');

    const info = [
        {
            title: "contact.address",
            desc: state.tenant && state.tenant.address && state.tenant.address.street ? address : '',

        },
        {
            title: "contact.email",
            desc: state.tenant ? state.tenant.supportEmail : '',
        },
        {
            title: "contact.phone",
            desc: state.tenant ? state.tenant.supportPhone : '',
        },
    ];

    let btnText = "";
    if (liveChatEnabled) {
        btnText = translate("contact.chat-button");
    }


    return (
        <div className={`nc-PageContact overflow-hidden mb-12`}>
            <BgGlassmorphism/>

            <div className="container mb-12">
                <SectionHero
                    rightImg={'/cdn/content/contact_us.png'}
                    heading={translate("contact.title")}
                    btnText={btnText}
                    subHeading={translate("contact.heading")}
                    subHeading2=""
                    functionToCall="LiveChatWidget.call('maximize');"
                />

            </div>
            <div className="container ">
                <div>
                    <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">
                        <div className="max-w-sm space-y-8">
                            {info.map((item, index) => (
                                item.desc && (
                                    <div key={index}>
                                        <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                                            {translate(item.title)}
                                        </h3>
                                        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                {item.desc}
            </span>
                                    </div>
                                )
                            ))}
                            {/*if state.tenant.url = www.utazosim.hu render div*/}
                            {state.tenant && state.tenant.url !== "www.utazosim.hu" ? (
                                <div>
                                    <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                                        {translate("contact.socials")}
                                    </h3>
                                    <SocialsList className="mt-2"/>
                                </div>
                            ) : null}
                        </div>
                        <div>
                            <Formik
                                initialValues={{fullName: "", email: "", message: ""} as any}
                                validateOnMount={true}
                                validationSchema={validationSchema}
                                onSubmit={async (values, {resetForm}) => {
                                    console.log("Form submitted"); // Add this line
                                    console.log(values);

                                    // Handle form submission here
                                    // ...

                                    setIsFormDisabled(true);
                                    try {
                                        const response = await fetch("/api/email/support", {
                                            method: "POST",
                                            body: JSON.stringify(values),
                                            headers: {
                                                "Content-Type": "application/json",
                                            },
                                        });

                                        if (response.ok) {
                                            toast.custom(
                                                (t: Toast) => (
                                                    <Transition
                                                        appear
                                                        show={t.visible}
                                                        className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
                                                        enter="transition-all duration-150"
                                                        enterFrom="opacity-0 translate-x-20"
                                                        enterTo="opacity-100 translate-x-0"
                                                        leave="transition-all duration-150"
                                                        leaveFrom="opacity-100 translate-x-0"
                                                        leaveTo="opacity-0 translate-x-20"
                                                    >
                                                        <p className="block"
                                                           style={{color: state.tenant ? state.tenant.green : "black"}}>
                                                            {translate("contact.success-message")}
                                                        </p>
                                                    </Transition>
                                                ),
                                                {
                                                    position: "top-right",
                                                    duration: 5000,
                                                }
                                            );
                                            console.log("Message sent successfully!");
                                            setIsFormDisabled(false);
                                            //clear the form fields
                                            resetForm();

                                        } else {
                                            toast.custom(
                                                (t: Toast) => (
                                                    <Transition
                                                        appear
                                                        show={t.visible}
                                                        className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
                                                        enter="transition-all duration-150"
                                                        enterFrom="opacity-0 translate-x-20"
                                                        enterTo="opacity-100 translate-x-0"
                                                        leave="transition-all duration-150"
                                                        leaveFrom="opacity-100 translate-x-0"
                                                        leaveTo="opacity-0 translate-x-20"
                                                    >
                                                        <p className="block"
                                                           style={{color: state.tenant ? state.tenant.red : "black"}}>
                                                            {translate("contact.error-message")}
                                                        </p>
                                                    </Transition>
                                                ),
                                                {
                                                    position: "top-right",
                                                    duration: 5000,
                                                }
                                            );
                                            console.error("Error sending message");
                                            setIsFormDisabled(false);
                                        }
                                    } catch (error) {
                                        console.error("Error:", error);
                                        setIsFormDisabled(false);
                                    }

                                    resetForm();
                                }}
                            >
                                {({isSubmitting, isValid, resetForm}) => (

                                    <Form className="grid grid-cols-1 gap-6 ">
                                        <label className="block">
                                            <Label>{translate("contact.full-name")}</Label>
                                            <Field type="text" name="fullName"
                                                   className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300"
                                                   disabled={isSubmitting || isFormDisabled}/>
                                            <ErrorMessage name="fullName" component="div" className="text-red-500"/>
                                        </label>

                                        <label className="block">
                                            <Label>{translate("contact.email-address")}</Label>
                                            <Field
                                                type="email"
                                                name="email"
                                                placeholder="example@example.com"
                                                className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300"
                                                disabled={isSubmitting || isFormDisabled}
                                            />
                                            <ErrorMessage name="email" component="div" className="text-red-500"/>
                                        </label>

                                        <label className="block">
                                            <Label>{translate("contact.message")}</Label>
                                            <Field
                                                as="textarea"
                                                name="message"
                                                className="block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 placeholder-gray-300"
                                                rows={6}
                                                disabled={isSubmitting || isFormDisabled}
                                            />
                                            <ErrorMessage name="message" component="div" className="text-red-500"/>
                                        </label>

                                        <div>
                                            <ButtonPrimary type="submit"
                                                           disabled={isSubmitting || !isValid || isFormDisabled}
                                                           className="buyCardButton w-full bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-white">
                                                {translate("contact.send-message")}
                                            </ButtonPrimary>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default PageContact;
