import React from 'react';
import {Navigate, PathRouteProps, useLocation} from 'react-router-dom';
import {Translate} from 'react-jhipster';

import {useAppSelector} from '../../../app/config/localStorage';
import ErrorBoundary from "../../error/error-boundary";

interface IOwnProps extends PathRouteProps {
  hasAnyAuthorities?: string[];
  children: React.ReactNode;
}

export const PrivateRoute = ({ children, hasAnyAuthorities = [], ...rest }: IOwnProps) => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const sessionHasBeenFetched = useAppSelector(state => state.authentication.sessionHasBeenFetched);
  const account = useAppSelector(state => state.authentication.account);
  const isAuthorized = hasAnyAuthority(account.authorities, hasAnyAuthorities);
  const pageLocation = useLocation();

  if (!children) {
    throw new Error(`A component needs to be specified for private route for path ${(rest as any).path}`);
  }

  // if (!sessionHasBeenFetched) {
  //   return <div></div>;
  // }

  if (isAuthenticated) {
    if (isAuthorized) {
      return <ErrorBoundary>{children}</ErrorBoundary>;
    }

    return (
        <div className="nc-Page404">
          <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
            <header className="text-center max-w-2xl mx-auto space-y-2">
              <span
                  className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
        </span>
              <div className="pt-8">
                <Translate contentKey="global.error.403" />
              </div>
            </header>
          </div>
        </div>
    );
  }

  return (
      <Navigate
          to={{
            pathname: '/login',
            search: pageLocation.search,
          }}
          replace
          state={{from: pageLocation}}
      />
  );
};

export const hasAnyAuthority = (authorities: string[], hasAnyAuthorities: string[]) => {
  if (authorities && authorities.length !== 0) {
    if (hasAnyAuthorities.length === 0) {
      return true;
    }
    return hasAnyAuthorities.some(auth => authorities.includes(auth));
  }
  return false;
};

/**
 * Checks authentication before showing the children and redirects to the
 * login page if the user is not authenticated.
 * If hasAnyAuthorities is provided the authorization status is also
 * checked and an error message is shown if the user is not authorized.
 */
export default PrivateRoute;
