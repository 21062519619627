import React, {FC, useState} from "react";
import {MinusIcon, PlusIcon,} from "@heroicons/react/24/outline";
import {Link, useLocation} from "react-router-dom";
import Prices from "./Prices/Prices";
import ButtonPrimary from "./Button/ButtonPrimary";
import FAQAccordionInfo from "../../app/faq/esim/FAQAccordionInfo";
import {defaultPlanValue, IPlan} from "../../app/model/plan.model";
import {Image} from "@chakra-ui/react";
import {useNavigate} from "react-router";
import {Disclosure} from "@headlessui/react";
import {useAppSelector} from "../../app/config/localStorage";
import {humanFileSize} from "./SimView";
import {IOperator} from "../../app/model/operator.model";
import Amount from "./Amount";
import {useAppState} from "../../app/config/AppStateContext";
import FAQAccordionInfoNoTranslation from "../../app/faq/esim/FAQAccordionInfoNoTranslation";
import {translate} from "react-jhipster";
import {keyGenerator} from "../../app/config/utils";

export interface ProductQuickViewProps {
    className?: string;
    passedPlan: IPlan;
    allowBuy?: boolean;
}

export const planUrlName = (planName: string | undefined) => {
    if (!planName) {
        return "";
    }
    return planName.replaceAll(" ", "-").toLowerCase();
}

const ProductQuickView: FC<ProductQuickViewProps> = ({className = "", passedPlan, allowBuy = true}) => {
    const {state} = useAppState();
    const [plan, setPlan] = useState<IPlan>(passedPlan);
    const templates: IPlan[] = useAppSelector(state => state.template.templates);
    const navigate = useNavigate();
    const location = useLocation();

    window.history.replaceState(null, "", `/plan/${planUrlName(plan.name)}${location.search}`);

    const extractUniqueCountries = (): any => {
        return plan.operators.map((operator, index) => {
            return operator.continent;
        }).filter((x, i, a) => a.indexOf(x) === i);
    }

    const renderOperators = (continet: string): any => {
        const countries = plan.operators.filter((operator, index) => {
            return operator.continent === continet;
        }).filter((x, i, a) => i === a.findIndex((t) => (
            t.countryName === x.countryName
        )));
        return <div key={keyGenerator()} className={'text-justify'}>
            {countries.map((operator: IOperator, index: number) => (<div key={keyGenerator()}>
                <img
                    key={keyGenerator()}
                    style={{display: "inline", marginRight: "4px"}}
                    alt={operator.countryName}
                    src={`/api/content/image/country-flag/${operator.countryIso2.toLowerCase()}`}
                    className={'async-option-image'}

                />
                {translate(`global.countries.${operator.countryIso2}`)}{index === countries.length - 1 ? "" : " , "}</div>))}
        </div>;
    }

    const renderDataList = () => {
        if (!templates || !templates.length) {
            return null;
        }

        return (
            <div>
                <div>
                    <div className="flex-grow flex flex-wrap gap-3">
                        {templates
                            .filter((currentPlan, index) => currentPlan.locationZoneId === plan.locationZoneId)
                            .sort((a, b) => a.data - b.data)
                            .map((currentPlan, index) => {
                                const isActive = currentPlan.data === plan.data;
                                return (
                                    <Link
                                        to={`/plan/${currentPlan.id}${location.search}`}
                                        key={keyGenerator()}
                                        className={`buyCardButton relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                        text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 ${
                                            false
                                                ? "text-opacity-20 dark:text-opacity-20 cursor-not-allowed"
                                                : "cursor-pointer"
                                        } ${
                                            isActive
                                                ? `reverseBuyCardButton text-white `
                                                : " "
                                        }`}
                                        onClick={() => {
                                            setPlan(currentPlan);
                                        }}
                                        style={{flex: '1 1 calc(33.333% - 1rem)'}}
                                    >
                                        {humanFileSize(currentPlan.data)}
                                    </Link>
                                );
                            })}
                    </div>

                </div>


            </div>
        );
    };

    const renderSectionContent = () => {
        return (
            <div className="space-y-8">
                {/* ---------- 1 HEADING ----------  */}
                <div>
                    <h2 className="text-2xl font-semibold hover:text-primary-6000 transition-colors"
                        style={{color: state.tenant && state.tenant.primaryColor ? state.tenant.primaryColor : "black"}}

                    >
                        <Link to={"/checkout/" + plan.id}>{plan.name}</Link>
                    </h2>
                </div>

                {allowBuy ?
                    <div className="">

                        {state.tenant.url !== 'kelesim.com' && renderDataList()}

                    </div>
                    : null}

                {/*  */}
                <hr className=" border-slate-200 dark:border-slate-700"></hr>
                {/*  */}

                {/* ---------- 5 ----------  */}
                <FAQAccordionInfoNoTranslation
                    data={[
                        {
                            name: translate("order.description"),
                            content: plan.description
                        }
                    ]}
                />
                <Disclosure>
                    {({open}) => (
                        <>
                            <Disclosure.Button
                                className="flex items-center justify-between w-full px-4 py-2 font-medium text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
                                <span>{translate("global.text.coverage")}</span>
                                {!open ? (
                                    <PlusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400"/>
                                ) : (
                                    <MinusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400"/>
                                )}
                            </Disclosure.Button>
                            <Disclosure.Panel
                            >
                                <ul>
                                    {extractUniqueCountries().map((continent: string, index: number) => {
                                        return <div key={keyGenerator()}>
                                            <li key={keyGenerator()} className={'pb-3 pt-3 font-medium'}>{continent}</li>
                                            <ul key={keyGenerator()} className={'pl-3 space-y-6 md:pl-12'}>
                                                {renderOperators(continent)}
                                            </ul>
                                        </div>
                                    })}
                                </ul>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
                {allowBuy ?
                    <ButtonPrimary
                        className="buyCardButton flex-1 flex-shrink-0 w-full bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-white"
                        onClick={() => {
                            navigate(`/checkout/${plan.id}`);
                        }}
                    >
                    <span className="ms-3">
                                                                                   <Amount amount={plan.price}
                                                                                           currency={plan.currency || ''}/>

                    </span>
                    </ButtonPrimary> : null}
            </div>

        );
    };

    return (
        <div className={`nc-ProductQuickView ${className}`}>
            <div className="lg:flex">
                <div className="w-full ">
                    {renderSectionContent()}
                </div>
            </div>
        </div>
    );
};

export default ProductQuickView;
