import React from 'react';
import {IOperator} from "./model/operator.model";
import {Tooltip} from 'react-tooltip'
import {translate} from "react-jhipster";

interface IPlan {
    countries: string[];
    // Add other properties as needed
}

interface Props {
    zones: IOperator[];
    withCountryName?: boolean;
}


const CountryFlags: React.FC<Props> = ({zones, withCountryName= false}) => {
    return (
        <div className={'hidden xsm:block lg:block md:block sm:block'}>
            {zones
                .filter((x, i, a) => i === a.findIndex((t) => (
                    t.countryName === x.countryName
                )))
                .map((operator: IOperator, index: number) => {
                    let random = (Math.random() + 1).toString(36).substring(7);
                    return (
                        <span key={index} id={`flagTooltip-${index}-${random}`}>
                    <img
                        style={{display: "inline", marginRight: "4px"}}
                        alt={operator.countryName}
                        src={`/api/content/image/country-flag/${operator.countryIso2.toLowerCase()}`}
                        className={'async-option-image'}

                    />
                            {withCountryName? operator.countryName : null}
                            {/* Replace with actual tooltip component and properties */}
                            <Tooltip anchorSelect={`#flagTooltip-${index}-${random}`} place="top">
                        {translate(`global.countries.${operator.countryIso2}`)}
                    </Tooltip>
                </span>
                    )
                })}
        </div>
    );
};

export default CountryFlags;
